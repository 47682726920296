<template>
  <el-dialog
    title="文件列表"
    :visible.sync="dialogVisible"
    width="500px"
    :close-on-click-modal="false"
    class="br-dialog"
  >
    <el-table :border="true" :data="protocolInfo" class="br-table" style="width: 100%">
      <el-table-column label="文件名称" prop="fileName" />

      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button size="mini" type="success" class="table-detail-button">
            <a :href="downloadPdf(scope.row.url)" :download="scope.row.fileName">查看</a>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>
<script>
import { getProtocolByFinaceId } from '@/api/tradeFinancing.js'
export default {
  props: {
    financeId: {
      type: String,
      default: () => ('')
    }
  },
  data() {
    return {
      dialogVisible: false,
      protocolInfo: []
    }
  },
  watch: {
    dialogVisible(newV) {
      if (newV) {
        getProtocolByFinaceId(this.financeId, res => {
          this.protocolInfo = res.data
        })
      }
    }
  }
}
</script>
